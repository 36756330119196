import Bollettino from "./Components/Bollettino";
import React, { useEffect } from "react";
import NavBar from "./Components/NavBar";
import Footer from "Components/Footer";
import Form from "Components/Form";
import { BrowserRouter,Routes,Route } from 'react-router-dom';  
import Home from "Components/Home";
import TokenVerify from "Components/TokenVerify";
import SmsVerify from "Components/SmsVerfiy";
import Login from "Components/Login";
import ProtectedRoutesUser from "Components/ProtectedRoutes";
import ProtectedRouteAdmin from "Components/ProtectedRouteAdmin";
import ContrattoRes from "Components/ContrattoRes";
import ResellerCp from "Components/ResellerCp";
import Layout from "Components/Layout";
import AdminCp from "Components/AdminCp";
function App() {
    
    const [status,setStatus] = React.useState({
        status: false,
        Role: ""
    })
    const [loading,setLoading] = React.useState(true)
    useEffect(() => {
        setLoading(true)
        if (localStorage.getItem("logintoken") === null) {
            setStatus({status: false,Role: ""})
            setLoading(false)
            return
        }
        Login.fetch().then(res => {
            setStatus({
                status: res.data.status,
                Role: res.data.Role
            })
        }).finally(() => setLoading(false))
    },[])
    if (loading) return <></>
    return (
        <div style={{display: 'flex',flexDirection: 'column',minHeight: '100vh'}}>
            <BrowserRouter>
                <NavBar setStatus = {setStatus} />
                <Routes>
                    <Route path="/TokenVerify" element={<TokenVerify />}/>
                    <Route path='/Bollettino' element={<Bollettino />} />
                    <Route path='/Contratto' element={<Form/>} />
                    <Route path='/' element={<Home />} />
                    <Route path='/SmsVerify' element={<SmsVerify />} />
                    <Route path="/Login" element={<Login.Login setStatus={setStatus}/>} />
                    <Route element={<ProtectedRoutesUser status={status}/>}>
                        <Route path="/ResellerForm" element={<ContrattoRes setStastus = {setStatus} />} />
                        <Route path="/ResellerCp" element={<ResellerCp />} />
                    </Route>
                    <Route path="/Layout" element={<Layout />} />
                    <Route element={<ProtectedRouteAdmin status={status}/>}>
                        <Route path="/AdminCp" element={<AdminCp />} />
                    </Route>
                </Routes>
                <Footer />
            </BrowserRouter>
            
        </div>
    )
}

export default App;
